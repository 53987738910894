<div style="height: 100vh;">
  <app-header [oneHeader]="'My Games'"></app-header>
  <div class="detailedGamesMainDiv" *ngIf="selectedExplore" style="padding-top: 0;">
    <div class="leftContainer">
      <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
        (click)="selectedExploreGame('digital')" *ngIf="allowedGameId && allowedGameId.includes(1)">
        <img src="../../../assets/img/digitalGame.svg"
          [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
        <b>Digital Games</b>
        <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
      </div>
      <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
        (click)="selectedExploreGame('physical')" *ngIf="allowedGameId && allowedGameId.includes(2)">
        <img src="../../../assets/img/physicalGame.svg"
          [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
        <b>Physical Games</b>
        <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
      </div>
      <div class="individualSelections" style="cursor: default;">
        &nbsp;
      </div>
    </div>
    <div class="rightContainer_myGame"
      [ngClass]="selectedExplore=='physical'?'bg-green':selectedExplore=='questico'?'bg-yellow':''">
      <div style="height: 100%;">

        <!-- Digital -->
        <!-- Digital -->

        <div class="myGameContainer" *ngIf="selectedExplore=='digital' && allowedGameId && allowedGameId.includes(1)">
          <div class="digital_header_main_div">
            <div style="margin-right: 3rem;">
              <img class="back_btn_img" src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                (click)="backClicked()">
            </div>
            <div style="display: flex; gap: 20px;align-items: center;height: 100%;">
              <div class="digital_header_sub_div">
                <div class="q_info_heading">Licenses</div>
                <div>&nbsp;</div>
                <div class="q_info_sub_heading" [ngClass]="{'alertColorRed': alertDigitalColorCssFlag.licenseLow}">Left
                  :</div>
                <div class="q_info_sub_num" [ngClass]="{'alertColorRed': alertDigitalColorCssFlag.licenseLow}">
                  <b>{{licensesDtls.left}}</b>
                </div>
                <div class="q_info_sub_heading">Purchased :</div>
                <div class="q_info_sub_num"><b>{{licensesDtls.purchased}}</b></div>
              </div>

              <div class="digital_header_sub_div">
                <div class="q_info_heading">Validity</div>
                <div>&nbsp;</div>
                <div class="q_info_sub_heading" [ngClass]="{'alertColorRed': alertDigitalColorCssFlag.expiringSoon}">
                  Access available till :</div>
                <div class="q_info_sub_num" [ngClass]="{'alertColorRed': alertDigitalColorCssFlag.expiringSoon}">
                  <b>{{validity.access_till | date:' dd MMMM yyyy'}}</b>
                </div>
                <div class="q_info_sub_heading">Purchased on :</div>
                <div class="q_info_sub_num"><b>{{validity.purchased_on | date:' dd MMMM yyyy'}}</b></div>
              </div>
            </div>
            <div style="flex: 1;">&nbsp;</div>
            <div class="digital_header_q_btn_div">
              <div>
                <button class="digital_header_q_btn" (click)="openReachOut()">
                  <span>Reachout</span>
                  <img src="../../../assets/reach_out_icon.svg" alt="">
                </button>
              </div>
              <div>
                <button class="digital_header_q_btn" (click)="goToGameReport()">
                  <span>Game Reports</span>
                  <img src="../../../assets/game_report.svg" alt="">
                </button>
              </div>
            </div>
          </div>
          <div class="myGames myGamesDis">
            <div class="myGame_img_div" *ngFor="let game of accessGameApiData"
              [ngClass]="{'gameExiredDigital': game.status == 0 && game.is_active == 0}"
              (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/img/myGames/vc.png" *ngIf="game.game_id=='1'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/bn_one_line.svg" *ngIf="game.game_id=='2'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/img/explore_wh.svg" *ngIf="game.game_id=='3'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/img/myGames/af.png" *ngIf="game.game_id=='5'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/img/myGames/bp.png" *ngIf="game.game_id=='6'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/img/Picture_Perfect_Logo.png" *ngIf="game.game_id=='4'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/great_ocean_kingdom_logo.svg" *ngIf="game.game_id=='14'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
              <img src="../../../assets/people_of_world_logo.svg" *ngIf="game.game_id=='15'"
                (click)="goToGameResource(game.game_id,game.status == 0 && game.is_active == 0)">
            </div>

          </div>

        </div>

        <!-- Physical -->
        <!-- Physical -->

        <div class="myGameContainer" *ngIf="selectedExplore=='physical' && allowedGameId && allowedGameId.includes(2)">

          <div class="digital_header_main_div" style="height: auto; padding-bottom: 26px;">
            <div style="margin-right: 3rem; align-self: center;">
              <img class="back_btn_img" src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                (click)="backClicked()">
            </div>
            <div style="display: flex; gap: 20px;align-items: center;height: 100%;">
              <div class="digital_header_sub_div">
                <div class="q_info_sub_heading">Game Kit :</div>
                <div class="q_info_sub_num"><b>{{totalGameKit}}</b></div>
              </div>

              <div class="digital_header_sub_div">
                <div class="q_info_sub_heading" [ngClass]="{'alertColorRed':alertPyColorCssFlag}">
                  Access available till :</div>
                <div class="q_info_sub_num" [ngClass]="{'alertColorRed': alertPyColorCssFlag}">
                  <b>{{validity.access_till | date:' dd MMMM yyyy'}}</b>
                </div>
              </div>
            </div>
            <div style="flex: 1;">&nbsp;</div>
            <div class="digital_header_q_btn_div">
              <div>
                <button class="digital_header_q_btn" (click)="openReachOut()">
                  <span>Reachout</span>
                  <img src="../../../assets/reach_out_icon.svg" alt="">
                </button>
              </div>
            </div>
          </div>









          <div class="myGames myGamesDis" style="height: 100%; margin-bottom: 2rem;">
            <div class="myGame_img_div_py" style="flex-direction:column;height: 250px;justify-content: space-between;"
              *ngFor="let item of pyhsicalGamesAllowed"
              [ngClass]="{'gameExiredDigital': item.status == 0 && item.is_active == 0}"
              (click)="goToGameResource(item.game_id,item.status == 0 && item.is_active == 0)">
              <div class="py_my_game_img_main_div">
                <img src="../../../assets/img/myGames/human_matrix.svg" alt="" *ngIf="item.game_id == 8">
                <img src="../../../assets/img/string_therory.png" alt="" *ngIf="item.game_id == 9">
                <img src="../../../assets/img/strom_selter.png" alt="" *ngIf="item.game_id == 10">
                <img src="../../../assets/img/assebembly_line.png" alt="" *ngIf="item.game_id == 11">
                <img src="../../../assets/img/magic_mats.png" alt="" *ngIf="item.game_id == 12">
                <img src="../../../assets/img/stranded.png" alt="" *ngIf="item.game_id == 13">
                <img src="../../../assets/friction_action_game.png" alt="" *ngIf="item.game_id == 16">
                <img src="../../../assets/lean_up_game.png" alt="" *ngIf="item.game_id == 17">
                <img src="../../../assets/traficjam_game.png" alt="" *ngIf="item.game_id == 18">
                <img src="../../../assets/vc_game_py.svg" alt="" *ngIf="item.game_id == 19">
              </div>
              <div class="game_kit_main_div">
                <div class="game_kit_sub_div">
                  <img src="../../../assets/img/myGames/game_kit_icon.svg" alt="">
                  <div class="game_kit_info">
                    <div>{{item.game_kits}}</div>
                    <div>Game Kits</div>
                  </div>
                </div>
              </div>
            </div>

          </div>












        </div>
        <div class="myGameContainer" *ngIf="selectedExplore=='questico'">
          <div class="myGames">
            <div class="myGameImg" style="height: 250px;width: 25%;">
              <div
                style="height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <div style="font-size: 34px;font-weight: 500;">QUESTICO</div>
                <!-- <div style="font-size: 34px;font-weight: 200;">Games</div> -->
              </div>
            </div>
            <div style="max-width: 27%;text-align: justify;">
              Use this race themed customizable platform to create a gamified learning experience for your cohort,
              either as a single player or team based game.This link will take you directly to the Questico dashboard
              where you can create or choose challenges and other parameters that will define your game.
            </div>
            <div class="myGameDate">
              <div class="myGameExpiry" style="width: 55%;text-align: center;" *ngIf="!adminFlag">
                <b>THIS IS TRIAL ACCOUNT ACTIVE FOR 21 DAYS</b>
              </div>
              <div class="gameResurce" style="margin-top:1rem;padding: 16px 33px;max-width: 15rem;font-size: 19px;"
                (click)="goToGameResource('questico')">
                Games
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>